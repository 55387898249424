import { BrowserRouter, HashRouter } from "react-router-dom"
import RouteSetup from "./route/RouteSetup"
import './assets/css/style.css'

export default function App(){
  return(
    <div>
      {/* <BrowserRouter basename={process.env.PUBLIC_URL}> */}
      <HashRouter>
      {/* <BrowserRouter> */}
        <RouteSetup/>
      {/* </BrowserRouter> */}
      </HashRouter>
      {/* </BrowserRouter> */}
    </div>
  )
}