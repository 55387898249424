import { Routes, Route } from 'react-router-dom'
import Main from '../pages/Main'

export default function RouteSetup(){
  return(
    <div>
      <Routes>
        <Route index element={<Main/>}/>
      </Routes>
    </div>
  )
}