import ReactFullpage from "@fullpage/react-fullpage"
import "../fullpage_custom.css"
import { useRef } from "react"

export default function Main(){
  const refSection = useRef([])

  return(
    <div>
      <ReactFullpage
        scrollOverflow={true}
        navigation={true}
        scrollingSpeed={1200}
        afterLoad={(index, nextIndex) => {
          document.querySelectorAll('.section').forEach((e) => {e.classList.remove('show')})
          nextIndex.item.classList.add('show')
        }}
        render={({ state, fullpageApi }) => {
          return (
            <div id="fullpage-wrapper">
              <div className="section section1" ref={(el) => {refSection.current[0] = el}}></div>
              <div className="section section2" ref={(el) => {refSection.current[1] = el}}>
                <div className="ceo-img"></div>
              </div>
            </div>
          )
        }}
      />
    </div>
  )
}